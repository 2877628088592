.header {
  h3 {
    margin-bottom: 12px !important;
  }
  h3::before {
    content: "";
    padding-left: 6px;
    border-left: 4px solid #206774;
  }
}
