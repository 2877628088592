@import "../../../semantic-ui/site/globals/color.variables";

.st0 {
  fill: #f4b569;
}
.st1 {
  fill: #0e0000;
}
.st2 {
  fill: #f08c4a;
}
.st3 {
  fill: #545252;
}
.cap1 {
  fill: @green;
}
.cap2 {
  fill: @red;
}
.st4 {
  fill: #e96668;
}
.st5 {
  fill: #ffffff;
}
.st6 {
  fill: #2954a3;
}
.st7 {
  font-family: inherit;
}
.st8 {
  font-size: 88px;
}
.st9 {
  font-family: inherit;
}