$radius: 3px;

.option {
    height: 24.5px;
    margin-bottom: 10px; }

.question {
    text-align: left; }

.progress {
    margin: 0px !important;
    border-radius: $radius !important;
    border: 0.5px solid lightgray !important;
    background-color: lightgray !important;

    div {
        border-radius: $radius !important; } }

.description {
    position: relative;
    top: -27px;
    left: 12px;
    line-height: 27px;
    text-align: left;
    font-family: sans-serif;
    font-size: 0.8em;
    user-select: none; }

.ans_count {
    font-size: 0.9em;
    text-align: left;
    color: darkgray; }
