.ad {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  max-height: 70px;
  cursor: pointer;
  width: 100%;

  img {
    object-fit: contain;
    max-height: 70px;
    width: 100%;
  }
}