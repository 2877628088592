.subheader {
  margin-top: 2px;
  font-family: inherit;
  color: gray;
  font-weight: normal;
  font-size: 12px;
  line-height: 1em;
}

.vertical_align_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.horizontal_align_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sans_serif {
  font-family: sans-serif !important;
}
